import type { SanityImageObject } from '@sanity/image-url/lib/types/types';

import type { ResourcePreview } from './types';
import { getLatestPublicationDate } from '~/integrations/sanity/helpers/getLatestPublicationDate';
import { getLocaleCodes } from '~/integrations/sanity/helpers/getLocaleCodes';
import { getResourceType } from '~/integrations/sanity/helpers/getResourceType';
import { toIdSlug } from '~/lib/toIdSlug';

// This interface describes the props needed to generate a `ResourcePreview`
export interface PreviewableSanityResource {
  _type: string;
  assets: {
    localeCode: string;
    publicationDate: string;
  }[];
  excerpt: string;
  id: string;
  resourceTypeSlug: string;
  slug: string;
  thumbnail: SanityImageObject | null;
  title: string;
}

interface ResourcePreviewOptions {
  ignoreNoLanguage?: boolean;
  localeCodeToName?: (localeCode: string) => string;
}

/**
 * @description Return an object usable in `ResourceGalleryCard` and `ResourceListCard`
 */
export function sanityResourceToResourcePreview(
  sanityResource: PreviewableSanityResource,
  options: ResourcePreviewOptions = {}
): ResourcePreview {
  const { excerpt, id, resourceTypeSlug, slug, thumbnail, title } = sanityResource;
  const { ignoreNoLanguage = false, localeCodeToName } = options;

  const localeCodes = getLocaleCodes([sanityResource], { ignoreNoLanguage });

  return {
    id,
    excerpt,
    idSlug: toIdSlug(id),
    localeNames: localeCodeToName ? localeCodes.map(localeCodeToName) : localeCodes,
    publicationDate: getLatestPublicationDate([sanityResource]),
    resourceType: getResourceType(resourceTypeSlug),
    slug,
    ...(thumbnail ? { thumbnailImageObject: thumbnail } : {}),
    title,
  };
}
