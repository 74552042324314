import { compareBase } from '~/lib/compareBase';
import { toUniqueArray } from '~/lib/toUniqueArray';

interface LocaleCodesOptions {
  ignoreNoLanguage?: boolean; // if true, omit any codes signifying that the asset doesn't use language
}

type PartialResource = {
  assets?: { localeCode: string }[];
};

const noLanguageLocaleCodes = ['nt', 'nv'];

/**
 * @description Return an array of the locale codes of all locales used in the assets of all the resources
 */
export function getLocaleCodes(resources: PartialResource[], options: LocaleCodesOptions = {}): string[] {
  const { ignoreNoLanguage = false } = options;
  return toUniqueArray(
    resources.reduce(
      (acc, resource) => [...acc, ...(resource?.assets || []).map(({ localeCode }) => localeCode)],
      [] as string[]
    )
  )
    .filter((localeCode) => !ignoreNoLanguage || !noLanguageLocaleCodes.includes(localeCode))
    .sort(compareBase('en'));
}
